import React, {useState} from 'react';
import './NavBar.css'
import {Button, Modal} from "react-bootstrap";
import {BrandName} from "../../static/data/constants";


export default function NavBar() {
    const [isOpen, setOpen] = useState(false);

    return (
    <div className="NavBar">
        <header>
            <Modal show={isOpen} onHide={()=>{setOpen(false)}}
                   style={{zIndex: 99999, display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,.5)"}}>
                <div style={{backgroundColor: "white", padding: 10, borderRadius: 10}}>
                    <h1>Join {BrandName}</h1>
                    <p>Click on the class you would like to sign up for.</p>
                    <div>
                        <div style={{backgroundColor: "#eee", borderRadius: 10, padding: 5, margin: 5, borderWidth: 0}}>

                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h3>Blackjack & Craps Academy Combo</h3>
                                <h3>$350</h3>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h6>July 22nd at 10 AM & 1 PM</h6>
                                <a href = "https://buy.stripe.com/9AQ039fAE327eqI288">Buy Now</a>
                            </div>
                        </div>
                        <div style={{backgroundColor: "#eee", borderRadius: 10, padding: 5,  margin: 5}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h3>Blackjack Academy</h3>
                                <h3>$200</h3>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h6>July 22nd at 10 AM</h6>
                                <a href = "https://buy.stripe.com/4gw8zFbkoeKP96o8wy">Buy Now</a>
                            </div>
                        </div>
                        <div style={{backgroundColor: "#eee", borderRadius: 10, padding: 5, margin: 5}}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h3>Craps Academy</h3>
                                <h3>$200</h3>
                            </div>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <h6>July 22nd at 1 PM</h6>
                                <a href = "https://buy.stripe.com/6oEdTZewA3274Q8cMN">Buy Now</a>
                            </div>
                        </div>
                    </div>
                    <p>Travel to Las Vegas and lodging in Las Vegas is not included.</p>
                    <a onClick={()=>{setOpen(false)}}>Close</a>
                </div>
            </Modal>
            <nav
                className="navbar position-fixed navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3"
                style={{width: "100%", zIndex:5000}}>

                <div className="container">
                    <a className="navbar-brand" href="/"><div style={{fontWeight: 'bold'}}><h1  className={"gradient-text"}  style={{fontWeight: 'bolder', fontSize: "2rem"}}>{BrandName}</h1></div></a>
                    <div className={"d-sm-inline-flex justify-content-between w-100 d-md-none"}>
                        {/*<Button onClick={()=>{setOpen(true)}} className="col-sm-12" style={{marginRight: 10, width: "100%", borderRadius: 20, border: "none", backgroundColor: "white", fontWeight: "bold", color:"#EB85A4", boxShadow: "0px 4px 15px -5px rgba(0,0,0,0.6)"}}>*/}
                        {/*    Join {BrandName}*/}
                        {/*</Button>*/}
                        <div className={"w-100 d-flex justify-content-center"}>
                            <a href={"mailto:pcontinopc@gmail.com"}>Contact Us</a>
                        </div>
                    </div>
                    <div className={"d-md-block justify-content-between d-none"}>
                        {/*<Button onClick={()=>{setOpen(true)}} className="col-sm-12" style={{marginRight: 10, width: "100%", borderRadius: 20, border: "none", backgroundColor: "white", fontWeight: "bold", color:"#EB85A4", boxShadow: "0px 4px 15px -5px rgba(0,0,0,0.6)"}}>*/}
                        {/*    Join {BrandName}*/}
                        {/*</Button>*/}
                        <div className={"w-100 d-flex justify-content-center"}>
                            <a href={"mailto:pcontinopc@gmail.com"}>Contact Us</a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </div>
  );
}


import React from 'react';
import BigImageDark from '../../static/images/cover-image.jpg';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import CardImage from "../../static/images/card-images/Card game-amico.svg";
import LearnImage from "../../static/images/card-images/Formula-amico.svg";
import MoneyImage from "../../static/images/card-images/Saving money-amico.svg";
import './Home.css';
import {BrandName} from "../../static/data/constants";

function Home() {



    return (
    <div className="Home">
      <div style={{height: "40vh",display: "table-cell", verticalAlign: "middle", maxWidth: "100vw", backgroundColor: "black", overflow: "hidden",backgroundSize: "cover",backgroundImage: "linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.7)),url("+BigImageDark+")", backgroundPosition: "center"}}>
          <div style={{display:"inline-block"}}>
              <div style={{alignContent:'center', display:"inline-block", height: "10vh",textAlign: 'center', width: "100%", zIndex: 999, fontWeight: 'bold', marginTop: "0vh",animation: "fade-up 2s forwards"}}>
                  <h1  className={"gradient-text"}  style={{fontWeight: 'bolder', fontSize: "4vmax"}}>{BrandName}</h1>
                  <h1 style={{color: 'white', fontWeight: 'lighter', fontSize: 20, padding: 10}}>All the basics of table game play that will allow you to have a great chance at winning</h1>

                  <div className={"row"} style={{justifyContent: "center", width: "100vw", marginLeft: 0, marginRight: 0}}>
                      <div style={{backgroundColor: "#CA2A7A", color: "white", borderRadius: 5 , paddingLeft: 10, paddingRight: 10}}>
                          {/*<p style={{marginBottom: 2}}>Save 95% of your gambling budget!</p>*/}
                          <p style={{marginBottom: 2}}>Not taking signups currently!</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
        <div className={"row"} style={{paddingTop: 50, paddingBottom: 20, justifyContent: 'center', minHeight: "60vh"}}>
            <ScrollAnimation className={"col-lg-3"} animateIn="animate__fadeIn" duration={1} offset={50} delay={200}>
                <div style={{animation: "fade-in 1s forwards"}}>
                    <img src={LearnImage} style={{maxWidth: "100%", maxHeight: 300}}/>
                    <h2>Learn</h2>
                    <p style={{fontSize:20}}>Learn the confusing game of craps and refine your blackjack to a minimum house edge.</p>
                </div>
            </ScrollAnimation>

                <ScrollAnimation className={"col-lg-3"} animateIn="animate__fadeIn" duration={1.5} offset={50} delay={200}>
                    <div >
                        <img src={CardImage} style={{maxWidth: "100%", maxHeight: 300}}/>
                        <h2>Play</h2>
                        <p style={{fontSize:20}}>Each class will include anywhere from 60 to 90 minutes of play time on real casino tables.</p>
                    </div>
                </ScrollAnimation>

                <ScrollAnimation className={"col-lg-3"} animateIn="animate__fadeIn" duration={2} offset={50} delay={200}>

                <div style={{animation: "fade-in 3s forwards"}}>
                    <img src={MoneyImage} style={{maxWidth: "100%", maxHeight: 300}} />
                    <h2>Save</h2>
                    <p style={{fontSize:20}}>
                        We'll teach you how to wager $100,000 at a theoretical cost of $100 to $400 on blackjack and craps, beating slot machines by 95%
                    </p>
                </div>
            </ScrollAnimation>
        </div>

        {/*<div style={{height: "30vh", display: 'flex', backgroundColor: '#86098D', alignItems: 'center', justifyContent:'center'}}>*/}
        {/*    <ScrollAnimation animateIn="animate__zoomIn" duration={.5} offset={50} delay={200}>*/}
        {/*        <h1 style={{fontSize:"3.5vmax", fontWeight: "bold", color: "white"}}>First Class on July 22nd</h1>*/}
        {/*        <h6 style={{fontSize:"1.5vmax", fontWeight: "bold", color: "white"}}>at 10 AM in Las Vegas, Nevada</h6>*/}
        {/*    </ScrollAnimation>*/}
        {/*</div>*/}

        <div className={"row"} style={{justifyContent: "center", paddingTop: 30, paddingBottom: 30}}>
            <div style={{maxWidth: "75vw", textAlign: "left"}}>
                <h1>Frequently Asked Questions</h1>
                <hr/>

                <h4 style={{fontWeight: "bold"}}>How does {BrandName} work?</h4>
                <p>The cost will be $200, the length of the class 2.5 hours. There are 2 classes you can take. Each one is $200 and 2.5 hours, each one will include approx 60 to 90 minutes of play on real casino tables. One class is for Blackjack and one class is for craps. </p>

                <h4 style={{fontWeight: "bold"}}>What if I have questions after the academy?</h4>
                <p>{BrandName} will continue to answer your questions and give you support via Phone for a full year after the academy has ended!</p>

                <h4 style={{fontWeight: "bold"}}>Are my winnings guaranteed?</h4>
                <p>{BrandName} should help you to become a better gambler and to save more of your money, however your results may vary widely.</p>

                <h4 style={{fontWeight: "bold"}}>Who is Vegaspaulyc?</h4>
                <p>Vegaspaulyc has 40 years of gambling experience and streams his gambling on TikTok and Compwallet TV.</p>

                <h4 style={{fontWeight: "bold"}}>How do you beat slot winnings by 95%?</h4>
                <p>Learn how to wager $100,000 in the casino at a theoretical cost of $100 to $400 on the 2 best casino games, blackjack and craps.
                    This is your theoretical loss and your results will vary widely. $100,000 bet on slots
                    has a theoretical loss of $9,600 at one major Las Vegas hotel casino corporation, therefore you are saving a minimum of 95 percent off your casino losses vs slot machines. </p>

                <h4 style={{fontWeight: "bold"}}>Why do we use the number $100,000?</h4>
                <p>Because a table games player, playing the better games can expect to put that total at risk during a Vegas trip when playing moderately. Of course you can play lighter or higher.</p>

            </div>
        </div>
    </div>
  );
};

export default Home;

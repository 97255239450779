    import React, {Dispatch} from 'react';
    import './Footer.css'
    import InstagramLogo from "../../static/images/social-icons/icons8-instagram.svg"
    import TwitterLogo from "../../static/images/social-icons/icons8-twitter.svg"
    import TikTokLogo from "../../static/images/social-icons/tiktok.svg"
    import {BrandName} from "../../static/data/constants";

    export default function Footer() {

        return (
            <div className="Footer mb-0 pb-0">
                <footer>

                    <div className="footer footer-loc position-sticky mb-0"
                         style={{fontSize: 20, backgroundColor: "#E79CD6", minHeight: 250}}>


                        <div className="row" style={{justifyContent: 'center'}}>
                            <div className="col-lg-3 d-lg-block d-none">
                                <BrandCard/>
                            </div>

                            <div className="col-lg-2">
                                {/*<a href={"https://checkout.stripe.com/pay/cs_live_Zb0AryCRrQjxGJm8pp2GXzXMLyVm8qidU0GImP3iMS6PKn61nVtBRpEY#fidkdWxOYHwnPyd1blppbHNgWjA0TzY3cHZPQk5VcHc8R3JqUWQ2RlxhPWl1RkZsMWdUY19kbU5EblMzf1B8ZjdVdFJ1f2FpbG58QEhNMDBuMH1tQU5GSHdMaDNpaGZLNjBsQF9wUH1qVHFRNTVUfXVPTXYwTycpJ3VpbGtuQH11anZgYUxhJz8nNmZ2NTduYXJKMFM1ZlVoPXJyJyknd2BjYHd3YHcnP2twaWl4JSUl"}>Subscribe to Floralia</a>*/}
                            </div>

                            <div className="col-lg-2">
                                {/*<a href={"https://mysuperportal.com/access/floralia/"}>Update Subscription</a>*/}
                            </div>
                            <div className="col-lg-2" style={{margin: 25, marginTop: 0}}>
                                <div className="row" style={{justifyContent: 'center'}}>
                                    <a href={"https://www.tiktok.com/@casinocompwallet"} style={{margin: 10}}>
                                        <img src={TikTokLogo} className={"filter-purple"}
                                             height={50}
                                             width={50}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="d-lg-none">
                            <BrandCard/>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }

    export function BrandCard()
    {
        return(
            <div>
                <h1  className={"gradient-text"}  style={{fontWeight: 'bolder', fontSize: "2rem"}}>{BrandName}</h1>
                <p style={{marginTop: "2px",marginBottom: 0, fontSize: 10}}>Copyright © Comp Wallet Corp</p>
            </div>
    )}

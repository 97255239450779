import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import NavBar from "./components/navbar/NavBar";
import NotFound from "./pages/error/not-found/NotFound";
import Attributions from "./pages/attributions/Attributions";
import Subscribe from "./pages/subscribe/Subscribe";
function App() {


    return (
      <Router>
          <Switch>
              <Route path='/' exact={true}>
                  <NavBarView>
                      <Home/>
                  </NavBarView>
              </Route>
              <Route path='/content-attributions' exact={true}>
                  <NavBarView>
                      <Attributions/>
                  </NavBarView>
              </Route>
              <Route path='/subscribe'>
                  <NavBarView>
                      <Subscribe/>
                  </NavBarView>
              </Route>
              <Route path='/join'>
                  <NavBarView>
                      <Subscribe/>
                  </NavBarView>
              </Route>
              <Route path='/buy'>
                  <NavBarView>
                      <Subscribe/>
                  </NavBarView>
              </Route>
              <Route path='*'>
                  <NavBarView>
                      <NotFound/>
                  </NavBarView>
              </Route>

          </Switch>
      </Router>

  );
}

export function NavBarView(props: any) {
  return(
      <div id="modalRoot">
        <NavBar/>
        <div style={{paddingTop:50}}/>
          {props.children}
        <Footer />
      </div>
  )
}

export default App;

import { IModalState } from './types';
import {Constants} from './constants'
const init: IModalState = {
    isShown: false
};
export function modalReducer(state: IModalState = init,
                              action: any): IModalState {

    switch (action.type) {
        case Constants.SHOW_MODAL:
            return {isShown: action.payload.show}

        default:
            return state;
}}
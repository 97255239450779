import React from 'react';
import logo from '../../logo.svg';
import BigImageDark from '../../static/images/BigImageDark.jpg'
import './Subscribe.css';
import NicholasHeadshot from '../../static/images/NickHeadshot.jpg'
import RaulHeadshot from '../../static/images/higareda.raul.jpg'
import NickHeadshot from '../../static/images/CFOHeadshot.jpg'
import AboutCard from "../../components/AboutCard/AboutCard";
import CarsonHeadshot from "../../static/images/carson-headshot.jpg";
import CoryHeadshot from "../../static/images/cory-headshot.jpg";
import {BrandName} from "../../static/data/constants";

 async function getIP() {
    return fetch("https://api.ipify.org?format=json").then(res => res.text()).then(data => {return JSON.parse(data)["ip"]}).catch((e)=>{return "none";})
}

 async function onAddClick() {
/*
    const load = {
        ref: sessionStorage.getItem('init_ref'),
        ip_addr: await getIP(),
        user_id: localStorage.getItem('user_id')
    }

    await fetch(`https://script.google.com/macros/s/AKfycbyfHUO_7o18vVhMX47Sr0ptcPAmtwp5mHYELDRUQABa5ziMzowyrqp3XCKRig6esBd1/exec?ref=${load.ref}&ip_addr=${load.ip_addr}&user_id=${load.user_id}&user_agent=${navigator.userAgent}&page=subscribe&type=SubClicks`, {
        method: 'GET' });
*/


    window.location.href = "https://buy.stripe.com/3cs02kdwO5V0cPm8ww";
}

function Subscribe() {

  return (
      <div style={{padding: "50px", textAlign: 'center'}}>
            <h1>Taking you to the {BrandName} payment page..</h1>
          {onAddClick()}
      </div>
  );
}

export default Subscribe;

import { combineReducers, createStore } from 'redux';
import { modalReducer } from './modal/reducer';
import { IModalState } from './modal/types';

export interface IRootState {
    modal: IModalState
}

const store = createStore<IRootState, any, any, any>(
    combineReducers({
        modal: modalReducer
    }))

export default store;
import React from 'react';
import logo from '../../logo.svg';
import './NotFound.css';
import HappyImage from "../../../static/images/illustrations/Listening happy music-pana.svg";
function NotFound() {
  return (
    <div className="NotFound">
        <p>
            <img src={HappyImage} style={{height: 400}}/>
            <p><code>404</code> This page doesn't exist.</p>

        </p>
        <a
            className="Home-link"
            href="/"
        >Go Home</a>
    </div>
  );
}

export default NotFound;

import React from 'react';
import './Attributions.css';
function Attributions() {
  return (
      <div style={{padding: "30px", minHeight: "100vh"}}>
          <h1>Attributions</h1>
          <p>Any content on our website requiring attributions will be credited here.</p>
          <hr/>

            <h2>Home Page</h2>
            <p> <a href="https://storyset.com/people">Illustration by Freepik Storyset</a></p>
        <h2>Error Page</h2>
          <a href="https://storyset.com/people">Illustration by Freepik Storyset</a>
      </div>
  );
}

export default Attributions;
